export default function Fifth() {
    const videos = ['/videos/AlertsModule.mp4', '/videos/asset_management.mp4', '/videos/collaboration_Intranet.mp4', '/videos/exit_management.mp4', '/videos/helpdesk.mp4', '/videos/ldemployee.mp4', '/videos/performance_management.mp4', 'videos/pollssurveyemployeeview.mp4', 'videos/productivitytrackeremployeeview.mp4', 'videos/Qandle_s AttendanceModule.mp4', 'videos/Qandle_s LeaveModule.mp4', 'videos/Qandle_s My team_sModule.mp4'];
    const titles = ['Alerts Module', 'Assets Module', 'Intranet/Collaboration module', 'Exit Module', 'Grievance/ Helpdesk Module', 'Learning & Development Module', 'Performance Module', 'Polls & Surveys Module', 'Productivity Module', 'Attendance Module', 'Leaves Module', 'My Teams Module'];
    return (
        <div className="mx-12 video-box">
            <div className="text-center">
                <h3 className="text-xl mt-2 uppercase"><b>Qandle - </b> HRMS Introduction & its Usage</h3>
                <p className="my-2 text-base font-light text-stone-900 text-font">
                    A human resource management system (HRMS) is a software application used to store employee information and support various human resource functions, such as benefits, payroll, recruiting, training, etc.
                    <br/>
                    Let us introduce you to the HRMS, Biorev is using – Qandle.
                    Come & explore various modules of this HRMS portal.
                </p>
                <div className="flex overflow-auto h-[calc(100vh-185px)]">
                     <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-full">
                        {videos.map((video, i) => (
                            <div key={'video' + i}>
                                <h3 className="text-xl mt-2 uppercase">{titles[i]}</h3>
                                <video className="h-auto max-w-full rounded-lg w-full" src={video} controls />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}