export default function Eighth() {
    const clearStorages = () => {
        window.location.href = 'https://www.flexiquiz.com/SC/N/Biorev-HR-Induction_Quiz';
        localStorage.removeItem('processinfo');
    }
    return (
        <div className="mx-12">
            <div className="text-center">
                <h3 className="text-xl mt-2 uppercase">Congratulations</h3>
                <p className="my-2 text-base font-light text-stone-900 text-font">
                    Your onboaring process has almost completed.
                </p>
                <div className="flex align-center justify-center items-center h-[calc(100vh-185px)]">
                    <button onClick={() => clearStorages()} type="button" className="inline-flex items-center px-12 uppercase pt-3 pb-2 text-sm font-medium text-center text-white bg-violet-600 rounded-lg hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-violet-300 dark:bg-violet-700 dark:hover:bg-violet-700 dark:focus:ring-violet-700">Click Here To Continue to Quiz</button>
                </div>
            </div>
        </div>
    )
}