export default function First() {
    return (
        <div className="mx-12">
            <div className="text-center">
                <h3 className="text-xl mt-2 uppercase">Introduction</h3>
                <p className="my-2 text-base font-light text-stone-900 text-font">
                    Biorev is a leading 3D Rendering company in the USA, specializing in 3D Architectural Rendering Services, 3D Product Rendering, Virtual Walk-through Services of Real Estate, Virtual Assistant Services for Real Estate Sales, Technology solutions for web and mobile applications, Social Media Solutions, and IT solutions.
                    <br/>Let us hear what our esteemed VP “Mr. Sagar Gupta” has to say about it.
                </p>
                <iframe width="100%" className="h-[calc(100vh-235px)] rounded-lg" src="https://www.youtube.com/embed/5tx-Jn74xi4" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
            </div>
        </div>
    )
}