import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";

export default function Fourth() {
    return (
        <div className="mx-12">
            <div className="text-center">
                <h3 className="text-xl mt-2 capitalize">Let's read our Work Policies & procedures</h3>
                <p className="my-2 text-base font-light text-stone-900 text-font">
                    Company policies and procedures establish the rules of conduct within an organization, outlining the responsibilities of both employees and employers.<br/>
                    Depending on the needs of the organization, various policies and procedures establish rules regarding employee conduct, attendance, dress code, privacy and other areas related to the terms and conditions of employment.
                </p>
                <div className="h-[calc(100vh-185px)] overflow-y-auto text-start">
                    <Accordion>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            US Agreement
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <p className="text-content">
                                                US Agreement with Biorev, LLC, <strong>Head office at Dallas, United States of America.</strong>
                                                <br/>
                                                You will be working at <strong>Biorev Studios Pvt Ltd</strong> is based in <strong>Plot No. IT-C-6 Sebiz Square IT Park Road, Sector 67, Sahibzada Ajit Singh Nagar, Punjab 160062.</strong>
                                            </p>
                                            <ol className="pl-8 numeric">
                                                <li>11 Months Contract</li>
                                                <li>No PF/ESI/Tax Deduction</li>
                                                <li>Contract Renewable based on Performance.</li>
                                                <li>No Salary Slips</li>
                                            </ol>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            Leave policy
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <p className="mb-4">
                                                The objective of the leave policy is to give provision to the employees to balance their personal as well as professional life. The purpose of leave policy for employees is to lay guidelines regarding when to avail leave and the process to take leave with pay, leave without pay, Leave of absence policy and so on
                                            </p>

                                            <div className="pl-8">
                                                <ol className="pl-4 text-content ua">
                                                    <li className="mb-2">
                                                        <strong>Sick Leave / Casual Leave :</strong> You will be entitled to 12 days yearly including Probation period i.e. one sick leave per month allowed during Probation period
                                                    </li>

                                                    <ul className="circle">
                                                        <li>There are 12 Sick Leaves in a calendar year. It will be credited at the rate of 1 leave per month.</li>
                                                        <li>Employees will be eligible for accumulated sick Leave only after completion of probationary period.</li>
                                                        <li>If an employee is not using Sick Leave for a month will get accumulated in SL bank. This can be utilizing on the basis of emergency on obtaining medical proof, if applying for 2 or more sick leaves.</li>
                                                        <li>Leave application for Sick Leave must reach reporting manager and HR department at least 1 hour in advance.</li>
                                                    </ul>

                                                    <li className="mt-4 mb-2">
                                                        <strong>Privilege Leave :</strong>  You will be entitled to 15 days paid leave each year after confirmation of  probation period. During probation period any leave will be considered as unpaid.
                                                    </li>

                                                    <ul className="circle">
                                                        <li>There are 15 Earned Leaves in a calendar year.</li>
                                                        <li>Employees will be eligible for Earned Leave only after completion of probationary period. Earned leave for the period of probation will be credited to employees account on successful completion of Probationary period.</li>
                                                        <li>Earned Leave will be updated on a monthly basis for the leave earned during the month. It will be credited at the rate of 1.25 leave per month. For existing employees carried forward Earned Leave balance from previous year. Subjected to a maximum accumulation of 30 Earned Leaves.</li>
                                                        <li>
                                                            Leave application for Earned leave must reach reporting manger and HR department at least 5 days in advance.

                                                            <p className="pt-3">
                                                                For Example, at the end of 6 months of probation period the total accumulated leaves in the account, provided no leaves availed during probation period, will be 13.5 (6- Sick leaves and 7.5- Earned leaves)
                                                            </p>
                                                        </li>
                                                    </ul>

                                                    <li className="mt-4">
                                                        <strong>Sandwich Leave-</strong>
                                                    </li>

                                                    <ul className="circle">
                                                        <li>It implies that when term holidays fall between two availed leaves then in that case, they all merge together and they are considered as sandwich leaves. For example, Saturday & Sunday is a weekend of in a company and an employee takes Friday and Monday leave then i.e., Saturday & Sunday, the official holiday, will also be counted as a Sandwich leave making it Four days.</li>
                                                    </ul>

                                                    <li className="mt-4">
                                                        Holiday Calendar As applicable will be shared by HR department.
                                                    </li>
                                                    <li className="mt-4">
                                                        Weekly Offs for Entire India Team will be alternate Saturdays. For BD team will be all Saturdays Off.
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            Exit Policy (Notice/F&F)
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <div className="text-content">
                                                <strong>1.  Notice period Clause:</strong>

                                                <p className="mt-2">
                                                    The standard notice period to be served in case of resignation has been settled at 1 month. In case of resignation from your services during or after probation, you are required to provide 30 days’ notice (effective from the date of resignation) or pay in lieu thereof on the basis of approval from your Manager and HR Team as per the business requirement. The final date of relieving will be mutually finalized by your manager & HR Team and If you fail to serve the notice period as stated in terms of your appointment / communicated by HR Team or otherwise negotiated at the time of resignation or if unwilling to cooperate in processing a smooth exit, the management can initiate legal proceedings. A lawsuit may be filed for breach of agreement.
                                                </p>
                                            </div>

                                            <div className="text-content mt-8">
                                                <strong>2.	Full and Final Settlement:</strong>

                                                <p className="mt-2">
                                                    Full and Final Settlement will be completed in coming payroll from the last date of working including notice period. NDA/ NCA to be signed off by the employees on the day of resignation or before the settlement of amount.
                                                </p>
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            Attendance Policy (Qandle/Hubstaff)
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <p className="text-content">
                                                Punctuality is important for normal and smooth functioning of the organization. Arriving Late, excess absenteeism disrupts normal working of organization.
                                            </p>
                                            <p className="mt-4">
                                                The attendance will be marked by Qandle/Hubstaff (50% efficiency is mandatory else could be marked absent for the day).
                                            </p>
                                            <ol className="pl-8 text-content ua">
                                                <li className="mb-2">
                                                    <strong>For Business Development team: </strong>
                                                </li>
                                                <ul className="circle">
                                                    <li>The attendance will be finalized after validating the Hubstaff Reports along with Qandle Log ins.</li>
                                                </ul>


                                                <li className="mt-4 mb-2">
                                                    <strong>For rest of the teams: </strong>
                                                </li>
                                                <ul className="circle">
                                                    <li>A Qandle Time Tracking application will have to be installed in your system, The log in credentials will be shared by the HR department on email.</li>
                                                    <li>Qandle Log ins will only be considered for finalizing the attendance.</li>
                                                </ul>
                                            </ol>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            Shift Timings
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <div className="text-content">
                                                <p className="mb-2">9 hours including break. Shift time to be decided as per business requirements.</p>
                                                <ul className="pl-8 circle">
                                                    <li>Morning Shift: 9:30 AM – 6:30 PM</li>
                                                    <li>Evening Shift: 6:00 PM – 03:00 AM </li>
                                                    <li>Day Shift: 11 AM to 9 PM</li>
                                                    <li>Overlapping Shift: 1 PM to 10 PM</li>
                                                </ul>
                                                <p>(Shift can be changed after discussion with the employee)</p>
                                            </div>

                                            <div className="text-content pt-8">
                                                <p className="mb-2"><strong><u>How Attendance will be Marked:</u></strong> </p>
                                                <ol className="pl-8 ua">
                                                    <li><strong>For In-office Employees:</strong> The Punches will be registered by the Biometric Machine: Employees will be issued access Cards for the same.</li>
                                                    <li className="mt-2"><strong>For WFH Employees:</strong> you need to Clock in/Clock out from Qandle Portal and also register your breaks in the same.</li>
                                                </ol>
                                            </div>

                                            <div className="text-content pt-8">
                                                <p className="mb-2"><strong><u>Buffer/ Grace Time: 15mins</u></strong> </p>
                                                <ul className="pl-8 circle">
                                                    <li>Any punch in after grace time will be considered as “Late Coming” </li>
                                                    <li>After 3 Late comings all latest will be consider as Half Day</li>
                                                    <li>Any kind of change in the shift must be prior approved by management in mail.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            IT Assets Policy
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <div className="text-content">
                                                <p className="mb-2">
                                                    This policy establishes the minimum requirements and responsibilities for the inventory and management of information technology (IT) assets owned by Biorev
                                                    <br /><br />
                                                    For the purposes of this policy, IT assets include the following:
                                                </p>
                                                <ul className="pl-8 circle">
                                                    <li>Computers</li>
                                                    <li>Monitors</li>
                                                    <li>Laptops</li>
                                                    <li>Camera equipment</li>
                                                    <li>Video equipment</li>
                                                    <li>Printers</li>
                                                    <li>Copiers</li>
                                                    <li>Headphones</li>
                                                </ul>
                                            </div>

                                            <div className="text-content pt-4">
                                                <p className="mb-2">
                                                    <strong>Policy Statement</strong>
                                                </p>
                                                <ol className="pl-8 ua">
                                                    <li>All employees have a duty of care to protect IT assets whether they are in use, stored, in transit, or in a state of disposal.</li>
                                                    <li>IT assets must be protected against physical or financial loss, whether by theft, mishandling, or accidental damage, either through primary prevention (e.g., physical security) or remediation.</li>
                                                    <li>All employees are supposed to sign/acknowledge the Asset Return Policy clause which states the terms & conditions of handling/returning the IT assets during the tenure and while separation. </li>
                                                    <li>Kindly check HRMS portal for acknowledging the document.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            Holiday Calendar
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <div className="text-content">
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div className="w-full">
                                                        <table className="border tbl-full">
                                                            <thead>
                                                            <tr>
                                                                <th className="text-center p-2" colSpan="3">
                                                                    US Holiday List - Jan- Dec 2023
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Date</th>
                                                                <th>Holiday</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>Sunday</td>
                                                                <td>01-01-2023</td>
                                                                <td>New year</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monday</td>
                                                                <td>29-05-2023</td>
                                                                <td>Memorial Day</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tuesday</td>
                                                                <td>04-07-2023</td>
                                                                <td>Independence Day</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monday</td>
                                                                <td>04-09-2023</td>
                                                                <td>Labor Day</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Thursday</td>
                                                                <td>23-11-2023</td>
                                                                <td>Thanksgiving</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tuesday</td>
                                                                <td>26-12-2023</td>
                                                                <td>Christmas Day</td>
                                                            </tr>
                                                            </tbody>
                                                            <tfoot>
                                                            <tr>
                                                                <th className="p-3 text-left" colSpan="3">All Saturday's off</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-content mt-8">
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div className="w-full">
                                                        <table className="border tbl-full">
                                                            <thead>
                                                            <tr>
                                                                <th className="text-center p-2" colSpan="3">
                                                                    India Holiday List - Jan- Dec 2023
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Date</th>
                                                                <th>Holiday</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>Sunday</td>
                                                                <td>01-01-2023</td>
                                                                <td>New year</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Thursday</td>
                                                                <td>26-01-2023</td>
                                                                <td>Republic Day</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Wednesday</td>
                                                                <td>08-03-2023</td>
                                                                <td>Holi</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tuesday</td>
                                                                <td>15-08-2023</td>
                                                                <td>Independence Day</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monday</td>
                                                                <td>02-10-2023</td>
                                                                <td>Mahatma Gandhi Jayanti</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tuesday</td>
                                                                <td>24-10-2023</td>
                                                                <td>Dussehra</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Sunday</td>
                                                                <td>12-11-2023</td>
                                                                <td>Diwali</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monday</td>
                                                                <td>13-11-2023</td>
                                                                <td>Diwali</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monday</td>
                                                                <td>27-11-2023</td>
                                                                <td>Guru Nanak Birthday</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monday</td>
                                                                <td>25-12-2023</td>
                                                                <td>Christmas</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="w-full">
                                                        <table className="border">
                                                            <thead>
                                                            <tr>
                                                                <th className="text-center p-2">
                                                                    Saturday's off
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr><td>14-01-2023</td></tr>
                                                            <tr><td>28-01-2023</td></tr>
                                                            <tr><td>11-02-2023</td></tr>
                                                            <tr><td>25-02-2023</td></tr>
                                                            <tr><td>11-03-2023</td></tr>
                                                            <tr><td>25-03-2023</td></tr>
                                                            <tr><td>08-04-2023</td></tr>
                                                            <tr><td>22-04-2023</td></tr>
                                                            <tr><td>06-05-2023</td></tr>
                                                            <tr><td>20-05-2023</td></tr>
                                                            <tr><td>03-06-2023</td></tr>
                                                            <tr><td>17-06-2023</td></tr>
                                                            <tr><td>01-07-2023</td></tr>
                                                            <tr><td>15-07-2023</td></tr>
                                                            <tr><td>29-07-2023</td></tr>
                                                            <tr><td>12-08-2023</td></tr>
                                                            <tr><td>26-08-2023</td></tr>
                                                            <tr><td>09-09-2023</td></tr>
                                                            <tr><td>23-09-2023</td></tr>
                                                            <tr><td>07-10-2023</td></tr>
                                                            <tr><td>21-10-2023</td></tr>
                                                            <tr><td>04-11-2023</td></tr>
                                                            <tr><td>18-11-2023</td></tr>
                                                            <tr><td>02-12-2023</td></tr>
                                                            <tr><td>16-12-2023</td></tr>
                                                            <tr><td>30-12-2023</td></tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ open }) => (
                                <>
                                    <AccordionHeader className="flex justify-between border mb-2  block text-start w-full py-1.5 px-4 border-violet-200 rounded-lg">
                                        <span className='text-base font-normal capitalize text-violet-800 tracking-normal text-gray-900'>
                                            Appraisal Policy
                                        </span>
                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fill="text-violet-800" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                    </AccordionHeader>

                                    <AccordionBody>
                                        <div className="accordion-body text-base font-light text-stone-900 text-font px-3 pb-3">
                                            <p className="p-right">Policy Effective Date: 01/01/23</p>
                                            <div className="clearfix"></div>
                                            <div className="text-content pt-2">
                                                <h3 className="title-3 mb-2">1.	Objective</h3>
                                                <p>This policy provides a guide to employees on the purpose of the performance appraisal and to ensure that the work performance and training needs of every employee are managed effectively and fairly. While this policy applies to all employees of Biorev, there may be some cases where the process is amended so that it is appropriate to the role.</p>

                                                <h3 className="title-3 pt-4">2.	Scope of policy</h3>
                                                <p>A performance appraisal gives individual employees and their manager an opportunity to review performance, work content, loads and volume, to look back on what has been achieved during the past year then agree future objectives. It is also the time an employee can agree personal objectives, and any learning and development requirements which may help.</p>

                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-4">A. The appraisal system is designed to:</h3>
                                                    <ul className="pl-8 circle">
                                                        <li>Be a positive process.</li>
                                                        <li>Raise the quality of services provided by motivating.</li>
                                                        <li>Increase job satisfaction.</li>
                                                        <li>Identify appropriate training and development requirements.</li>
                                                    </ul>
                                                    <p>Policy aims Biorev recognizes that employees perform most effectively when they have clear expectations of their job role and purpose, their own targets or objectives, and of the wider aims of Biorev.</p>
                                                </div>

                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-4">B. The policy aims to ensure that employees: </h3>
                                                    <ul className="pl-8 circle">
                                                        <li>Know what is expected of them i.e. the required standard of performance and how they should do their jobs.</li>
                                                        <li>Receive feedback which aims to improve and develop performance and recognize their achievements. </li>
                                                        <li>Identify areas where improvement is required and training and development needs. </li>
                                                        <li>
                                                            Have a mutually agreed plan to achieve both development goals and employee career development.
                                                            Roles and Responsibilities
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-4">C. Biorev is responsible for: </h3>
                                                    <ul className="pl-8 circle">
                                                        <li>Setting sustainable standards. </li>
                                                        <li>Providing advice and guidance to managers on implementing the policy. </li>
                                                        <li>Ensuring that appropriate training and development is available for managers and employees.</li>
                                                        <li>Appraising employees in a fair and objective manner against agreed objectives and action plans.</li>
                                                        <li>Following up actions arising from appraisals.</li>
                                                        <li>Ensuring that a written record of the appraisal meetings is completed.</li>
                                                        <li>Informing employees how the appraisal scheme works and how it will affect them.</li>
                                                    </ul>
                                                </div>

                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-4">D. Managers are responsible for: </h3>
                                                    <ul className="pl-8 circle">
                                                        <li>Ensuring each member of staff in their team is clear about what is expected of them.</li>
                                                        <li>Ensuring that new employees have work targets and objectives set as part of induction. </li>
                                                        <li>Meeting employees on a regular basis to review progress. </li>
                                                    </ul>
                                                </div>

                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-4">E. Employees are responsible for:</h3>
                                                    <ul className="pl-8 circle">
                                                        <li>Taking an active role in reviewing their performance appraisal and target setting.</li>
                                                    </ul>
                                                </div>

                                                <h3 className="title-3 pt-4">3.	Eligibility</h3>
                                                <p>All employees who have complete a tenure of 3 months are provided the performance review and consideration for merit pay increases as warranted.</p>

                                                <h3 className="title-3 pt-4">4.	Appraisal Process/Policies</h3>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-2">A. Performance Review Schedule</h3>
                                                    <p>Performance appraisals are conducted quarterly on dates announced by HR. Each Biorev’s manager is responsible for the timely and equitable assessment of the performance and contribution of subordinate employees.</p>
                                                </div>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-4">B. Performance Ratings</h3>
                                                    <p>At the end of each employee’s performance appraisal, the Manager is required to classify the performance with the prescribed ratings given below, based on the various parameters set and attributes of the employee</p>
                                                    <div className="text-center pt-3">
                                                        <table className="border cst-tbl">
                                                            <thead>
                                                                <tr>
                                                                    <th>Evaluation Criteria</th>
                                                                    <th>Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-1 text-left">Outstanding</td>
                                                                    <td>5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pl-1 text-left">Exceeds Requirements</td>
                                                                    <td>4</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pl-1 text-left">Meets Requirements</td>
                                                                    <td>3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pl-1 text-left">Need Improvement</td>
                                                                    <td>2</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pl-1 text-left">Unsatisfactory</td>
                                                                    <td>1</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h3 className="title-3 pt-6">Proposed increments for the year 2023 & onwards</h3>
                                                    <div className="text-center pt-3">
                                                        <table className="border cst-tbl">
                                                            <thead>
                                                                <tr>
                                                                    <th>Employee's Final Rating</th>
                                                                    <th>Proposed % of Salary Hike</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>4.5 to 5</td>
                                                                    <td>10%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>4 to 4.4</td>
                                                                    <td>9%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>3.5 to 3.9</td>
                                                                    <td>8%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>3 to 3.4</td>
                                                                    <td>6%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2.5 to 2.9</td>
                                                                    <td>0%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2 to 2.4</td>
                                                                    <td>PIP</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Below 2</td>
                                                                    <td>Termination</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-4">C. Promotion/Hikes</h3>
                                                    <p>A performance appraisal does not always result in an automatic salary increase. The employee's overall performance and salary level relative to position responsibilities must be evaluated to determine whether a salary increase is warranted. Out-of-cycle salary increases must be preapproved by the department manager, human resource (HR) director and Biorev’s VP.</p>

                                                    <p className="pt-4">Types of Appraisals/Promotion applicable:</p>
                                                    <div className="pl-8">
                                                        <h3 className="title-3">•  Annual Increments based on CGA ratings – No change in Job Title</h3>
                                                        <p><u>The amount of Annual hike offered based on the ratings ranges between 6% to 10% maximum.</u></p>

                                                        <h3 className="title-3">•  Pay Grade Promotion - change in Job Title and Salary</h3>
                                                        <p><u>Incase on Role Enhancement, the maximum hike offered as per the company policy is 4%.</u></p>

                                                        <h3 className="title-3">•  Title promotion - Change in Job Title without salary hike </h3>
                                                        <p><u>Any employee who is capable of handling additional responsibilities/ is not performing or required in the current role which leads to the change in job title but not qualifies for a Salary hike based on the eligibility criteria like tenure, skills, performance etc. </u></p>

                                                        <p>**Any Exceptions to above will be absolutely on management’s discretion</p>
                                                    </div>

                                                </div>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-2">D. Processes</h3>
                                                    <p>
                                                        HR will establish the format and timing of all review processes. The completed evaluations will be retained in the employee's personnel file.
                                                        <br/><br/>
                                                        Salary increase requests must be supported by a performance appraisal for salary change processing. Managers may not discuss any proposed action with the employee until all written approvals are obtained.
                                                        <br/><br/>
                                                        HR will review all salary increase/adjustment requests to ensure compliance with company policy and that they fall within the provided guidelines.
                                                        <br/><br/>
                                                        The HR Team has the right to change, modify or approve exceptions to this policy at any time with or without notice.
                                                    </p>
                                                </div>

                                                <h3 className="title-3 pt-4">5.	Appraisal Process- Execution</h3>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-2">Self-assessment </h3>
                                                    <p>Employees must be informed of the appraisal at least 4 weeks before the appraisal one-to-one meeting. A copy of the appraisal self-assessment form should be given to the employee so the employee has an opportunity to contribute. A copy of the completed self-assessment form should be returned to the manager 1 week before the appraisal one-to-one meeting. </p>
                                                </div>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-2">Appraisal Performance </h3>
                                                    <p>The manager will use the appraisal performance checklist to prepare for an appraisal meeting by considering what criteria to use to measure employee performance this will set the measure for performance over the next year.</p>
                                                </div>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-2">The one-to-one meeting</h3>
                                                    <p>
                                                        The appraisal one-to-one meeting will be carried out in private so as to provide for confidentiality. Sufficient time will be allocated so that the meeting is unhurried and any discussion is properly considered. However, it must be understood by the employee and the manager that information discussed in an appraisal may need to be disclosed to others.
                                                        <br/><br/>
                                                        The one-to-one form should be used to record an employee / manager meeting. The manager should complete this form at, or immediately following the meeting, ensuring that it is signed by both themselves and the employee. A copy will be given to the employee.
                                                    </p>
                                                </div>
                                                <div className="pl-4">
                                                    <h3 className="title-3 pt-2">The Appraisal Record</h3>
                                                    <p>When an appraisal is completed, it should be recorded using the appraisal form. This form should be completed by the appraiser and countersigned by the employee and the appraiser’s manager so the employee is aware of the competencies that are critical for effective performance of that role. A copy of the form should be given to the employee as soon as possible. Employees may use as a last resort BIOREV grievance procedure to resolve problems as to the accuracy or the fairness of the appraisal.</p>
                                                </div>

                                                <h3 className="title-3 pt-4">6.	Non-Compliance </h3>
                                                <p>All employees have a role to play in enforcing the policy and are required to deal with any observed or reported breaches. Should employees feel apprehensive about their own safety in regard to addressing any breach, they should seek senior management support. Failure to comply with this policy may lead to a lack of clarity over job role, learning needs or expected standards of performance, resulting in reduced effectiveness or efficiency, underperformance and putting service delivery at risk. Any member of staff refusing to observe the policy will be liable to disciplinary action in accordance with BIOREV ’s Disciplinary Policy up to and including dismissal.</p>

                                                <h3 className="title-3 pt-4">7.	Implementation of the Policy</h3>
                                                <p>Overall responsibility for policy implementation and review rests with BIOREV senior management. However, all employees are required to adhere to and support the implementation of the policy. BIOREV will inform all existing employees about this policy and their role in the implementation of the policy. They will also give all new employees notice of the policy on induction to BIOREV. This policy will be implemented through the development and maintenance of procedures for appraisals and one-to-one meetings, using template forms, and guidance given to both managers and employees on the process.</p>

                                                <h3 className="title-3 pt-4">8.	Monitoring Policy </h3>
                                                <p>The policy will be monitored on an on-going basis, monitoring of the policy is essential to assess how effective BIOREV has been. Reviewing Policy This policy will be reviewed and, if necessary, revised in the light of legislative or codes of practice and organizational changes. Improvements will be made to the management by learning from experience and the use of established reviews. </p>

                                                <h3 className="title-3 pt-4">9.	Policy Amendments </h3>
                                                <p>Should any amendments, revisions, or updates be made to this policy it is the responsibility of BIOREV senior management to see that all relevant employees receive notice. Written notice and/or training will be considered.</p>

                                                <h3 className="title-3 pt-4">10. Additional Information</h3>
                                                <p>If you require any additional information or clarification regarding this policy, please contact your manager. In the unlikely event where you are unhappy with any decision made, you should use BIOREV 's formal Grievance Procedure.</p>

                                            </div>

                                            <div className="footer pt-12">
                                                Issued By <br/>
                                                HR Department <br/>
                                                Biorev Studios Pvt. Ltd.
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </>
                            )}
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}