const NotFound = () => {
    return (
        <>
            <div>
                <p>Not Found page</p>
            </div>
        </>
    )
};

export default NotFound;

