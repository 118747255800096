import { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function LandingPage() {
	const [go, setGo] = useState(0);
	const [nav, setNav] = useState(false);
	const [processinfo, setProcessInfo] = useState({
		name: '',
		activestep: 0,
		completedstep: 0
	});
	let navigate = useNavigate();
	const storeName = (name) => {
		setProcessInfo({ name: name, activestep: 1, completedstep: 0 });
	}

	const getStart = () => {
		if (processinfo.name == '') {
			toast.error("Please enter your name to continue...");
		} else {
			navigate('/started');
		}
	}

	useEffect(() => {
		localStorage.setItem("processinfo", JSON.stringify(processinfo));
	}, [processinfo]);

	return (
		<div className="flex justify-center h-screen items-center ">
			<div className="align-middle text-center lp">
				<TypeAnimation
					sequence={[
						"Welcome To BIOREV",
						() => {
							setGo(1);
						},
					]}
					wrapper="h1"
					repeat={0}
					cursor={false}
					speed={350}
				/>
				{go === 1 ? (
					<TypeAnimation
						sequence={[500, "Onboarding Process", () => {
							setNav(true);
						}]}
						wrapper="h2"
						repeat={0}
						cursor={false}
						speed={250}
					/>
				) : (
					""
				)}
				{nav ? <div className="relative mt-8 df">
					<input type="search" className="text-font block w-full p-3 pl-8  text-sm font-medium text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-purple-500 focus:border-purple-500 tracking-wide transition-all duration-500 outline-none" onChange={(e) => storeName(e.target.value)} placeholder="Enter your name to start your onboarding...." required />
					<button type="submit" className="text-white absolute right-[4px] bottom-[3px] bg-purple-700 hover:bg-purple-800 focus:ring-4 uppercase focus:outline-none focus:ring-purple-300 font-normal rounded-full text-sm transition-all duration-500  px-6 pt-3 pb-2 hover:px-8" onClick={getStart}>Continue</button>
				</div> : ''
				}
			</div>
			<ToastContainer />
		</div>
	);
}
