import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from '@mui/icons-material';

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}>
            <ArrowCircleLeftOutlined color="primary" />
        </div>
    );
}

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}>
            <ArrowCircleRightOutlined color="primary" />
        </div>
    );
}


export default function Third() {
    const settings = {
        dots: true,
        infinite: true,
        navigator: true,
        speed: 1000,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    const images = ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg'];

    return (
        <div className="mx-12">
            <div className="text-center">
                <h3 className="text-xl mt-2 uppercase">Let's see an overview</h3>
                <p className="my-2 text-base font-light text-stone-900 text-font">
                    Let us now have a look at the journey & progression of our company from its conception to now.
                </p>
                <div className="align-center items-center h-[calc(100vh-185px)]">
                    <Slider {...settings}>
                        {images.map((name, i) => (
                            <div key={'key' + i}>
                                <img src={'/img/' + name} className="rounded-lg border" />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}