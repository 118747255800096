export default function Second() {
    return (
        <div className="mx-12">
            <div className="text-center">
                <h3 className="text-xl mt-2 uppercase">Let's have a look on our Websites</h3>
                <p className="my-2 text-base font-light text-stone-900 text-font">
                    Let’s have a look at our Tri Brands
                </p>
                <div className="align-center items-center h-[calc(100vh-300px)]">
                    <div className="flex justify-center mt-8">
                        <div className="w-80 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                            <a href="https://biorev.com" target="_blank">
                                <img className="rounded-t-lg m-auto" src="//biorev.com/wp-content/uploads/2020/12/tn-biorev.jpg" alt="biorev" />
                            </a>
                            <div className="p-2 border-t bg-orange-200 rounded-b-lg">
                                <a href="https://biorev.com" target="_blank">
                                    <h5 className="text-lg font-normal uppercase text-slate-800 tracking-normal text-gray-900">Biorev</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-4 mt-12">
                        <div className="max-w-sm bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                            <a href="https://biorevtech.com" target="_blank">
                                <img className="rounded-t-lg m-auto" src="//biorev.com/wp-content/uploads/2020/12/tn-03.jpg" alt="biorevtech" />
                            </a>
                            <div className="p-2 border-t bg-violet-200 rounded-b-lg">
                                <a href="https://biorevtech.com" target="_blank">
                                    <h5 className="text-lg font-normal uppercase text-violet-800 tracking-normal text-gray-900">Biorev Technology</h5>
                                    <p>We are We are the technology arm of BIOREV – a premier 3D Rendering & Architectural Visualization company in the US.</p>
                                </a>
                            </div>
                        </div>

                        <div className="max-w-sm bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                            <a href="https://biorevstudios.com" target="_blank">
                                <img className="rounded-t-lg m-auto" src="//biorev.com/wp-content/uploads/2021/01/tn-biorev-studio-logo-2.jpg" alt="biroev studio" />
                            </a>
                            <div className="p-2 border-t bg-[#16aba966] rounded-b-lg">
                                <a href="https://biorevstudios.com" target="_blank">
                                    <h5 className="text-lg font-normal uppercase text-cyan-800 tracking-normal text-gray-900">Biorev Studios</h5>
                                    <p>
                                        We use cutting edge technologies to deliver high-end results and photo-realistic renders for our customers.
                                    </p>
                                </a>
                            </div>
                        </div>

                        <div className="max-w-sm bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                            <a href="https://biorevdigital.com" target="_blank">
                                <img className="rounded-t-lg m-auto" src="//biorev.com/wp-content/uploads/2020/12/tn-05.jpg" alt="biorev digital" />
                            </a>
                            <div className="p-2 border-t bg-[#ed7f2666] rounded-b-lg">
                                <a href="https://biorevdigital.com" target="_blank">
                                    <h5 className="text-lg font-normal uppercase text-slate-800 tracking-normal text-gray-900">Biorev Digital</h5>
                                    <p>
                                        Biorev Digital is the digital marketing arm of Biorev.com and aims at providing customized Digital Marketing services to its clients.
                                    </p>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}