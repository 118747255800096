export default function Seventh() {
    const videos = ['/videos/Skype Account Setup.mp4'];
    return (
        <div className="mx-12 video-box">
            <div className="text-center">
                <h3 className="text-xl mt-2 uppercase"><b>Skype Account Set up</b></h3>
                <p className="my-2 text-base font-light text-stone-900 text-font">
                    Lets learn how to create your official skype
                </p>
                <div className="flex overflow-auto h-[calc(100vh-185px)]">
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-full">
                        {videos.map((video, i) => (
                            <div key={'video' + i}>
                                <h3 className="text-xl mt-2 uppercase"></h3>
                                <video className="h-auto max-w-full rounded-lg w-full" src={video} controls />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}