import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";


import First from '../components/steps/First';
import Second from '../components/steps/Second';
import Third from '../components/steps/Third';
import Fourth from '../components/steps/Fourth';
import Fifth from '../components/steps/Fifth';
import Sixth from '../components/steps/Sixth';
import Seventh from '../components/steps/Seventh';
import Eighth from '../components/steps/Eighth';

export default function Starter() {
    const steps = [1, 2, 3, 4, 5, 6,7,8];
    let navigate = useNavigate();

    const [processinfo, setProcessInfo] = useState(JSON.parse(localStorage.getItem('processinfo')));

    const continueNext = () => {
        if (processinfo.activestep <= steps.length - 1) {
            setProcessInfo({ ...processinfo, activestep: processinfo.activestep + 1, completedstep: processinfo.activestep });
        }
    }

    const continuePrevious = () => {
        if (processinfo.activestep > 0) {
            setProcessInfo({ ...processinfo, activestep: processinfo.activestep - 1, completedstep: processinfo.completedstep - 1 });
        }
    }

    useEffect(() => {
        console.log(1)
        localStorage.setItem("processinfo", JSON.stringify(processinfo));
    }, [processinfo]);

    useEffect(() => {
        console.log(2)
        let n = localStorage.getItem('processinfo') ? JSON.parse(localStorage.getItem('processinfo')) : {};
        console.log(3)
        if (n && n.name) {
            console.log(4)
            //  setProcessInfo(JSON.parse(n));
        } else {
            console.log(5)
            navigate('/');
        }
    }, [])


    return (

        <div className="w-screen h-screen p-2">
            {processinfo ?
                <div>
                    <header className='border-b pb-2 border-dashed'>
                        <div className="flex justify-between items-center flex-wrap">
                            <div>
                                <img src="https://biorev.com/wp-content/uploads/2020/10/favicon.png" alt="logo" width={28} />
                            </div>
                            <div className='flex  items-center flex-wrap'>
                                <img src="/boom.gif" alt="boom" width={24} className='mr-2' />
                                <div className="text-sm font-normal tracking-wide text-zinc-800 uppercase">
                                    Dear <span className='font-medium text-blue-700 underline'> {processinfo.name}</span>, Welcome to Biorev
                                </div>
                                <img src="/boom.gif" alt="boom" width={24} className='ml-2' />
                            </div>
                            <div>
                            </div>
                        </div>
                    </header>
                    <section className='mt-1 h-[calc(100vh-96px)] overflow-x-auto overflow-y-hidden'>
                        {processinfo.activestep === 1 ?
                            <div><First /> </div> : ''}
                        {processinfo.activestep === 2 ?
                            <div><Second /> </div> : ''}
                        {processinfo.activestep === 3 ?
                            <div><Third /> </div> : ''}
                        {processinfo.activestep === 4 ?
                            <div><Fourth /> </div> : ''}
                        {processinfo.activestep === 5 ?
                            <div><Fifth /> </div> : ''}
                        {processinfo.activestep === 6 ?
                            <div><Sixth /> </div> : ''}
                        {processinfo.activestep === 7 ?
                            <div><Seventh /> </div> : ''}
                        {processinfo.activestep === 8 ?
                            <div><Eighth /> </div> : ''}
                    </section>
                    <footer className='relative border-t pt-2 border-dashed w-full'>
                        <div className="flex justify-between items-right flex-wrap">
                            <ul className="inline-flex items-center ml-2 steps">
                                {steps.map((step) => <li key={'step' + step} className={
                                    step === processinfo.activestep ? 'bg-purple-700' : (
                                        step <= processinfo.completedstep ? 'bg-green-700' : 'bg-gray-300')
                                }>{step}</li>)}
                            </ul>
                            <div>
                                {(processinfo.completedstep >= 1) ?
                                    <button type="button" className="text-gray-700 border border-gray-700 hover:bg-gray-700 hover:text-white font-medium rounded-full text-xs py-1 pr-4 pl-2.5 text-center transition ease-in-out duration-700 uppercase" onClick={continuePrevious}>
                                        <KeyboardArrowLeft fontSize="small" /><span className='relative top-[3px]'> Go Back </span>
                                    </button>
                                    : ''
                                }
                                {(processinfo.activestep < steps.length) ?
                                    <button type="button" className="ml-8 text-purple-700 border border-purple-700 hover:bg-purple-700 hover:text-white font-medium rounded-full text-xs py-1 pl-4 pr-2.5 text-center transition ease-in-out duration-700 uppercase" onClick={continueNext}>
                                        <span className='relative top-[3px]'> Continue </span><KeyboardArrowRight fontSize="small" />
                                    </button>
                                    : ''
                                }
                            </div>
                        </div>
                    </footer>
                </div>
                : <div>
                    Something is missing... Click here to reset the process
                </div>
            }
        </div>

    )
};
